import * as React from "react";
import Pattern from "../images/cupcakes.png";
import styled from "styled-components";
import Img from "gatsby-image"; // to take image data and render it

export const Header = styled.div`
  padding: 1.5rem;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    background-image: url(${Pattern});
  }
`;

export const Container = styled.div`
  padding: 0 1rem;
  max-width: 1024px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  text-align: center;
  position: relative;
  z-index: 2;
  color: #3e3e3e;
  font-size: 2.5rem;

  @media (max-width: 600px) {
    font-size: 1.75rem;
  }
`;

export const SubTitle = styled.h2`
  position: relative;
  z-index: 2;
  color: #3e3e3e;
  font-size: 2rem;
  margin-top: 0;
`;

export const SubSubTitle = styled.h3`
  position: relative;
  z-index: 2;
  color: #3e3e3e;
  font-size: 1.5rem;
  margin-top: 0;
`;

export const Images = styled.div`
  display: flex;
  margin-top: 5rem;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  flex-wrap: wrap-reverse;

  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;

export const ImagesItem = styled((props) => <Img {...props} />)`
  flex: auto;
  margin: 0.5rem;
  max-width: 33.333%;
  width: calc(25% - 1rem);
  flex: auto;

  @media (max-width: 900px) {
    min-width: 150px;
  }

  @media (max-width: 700px) {
    max-width: 100%;
    width: calc(33.333% - 1rem);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  & > div {
    margin: 1rem;
    max-width: 500px;
    min-width: 250px;
    font-size: 18px;
    flex: 1;

    a {
      text-decoration: underline;
      font-weight: bold;
      color: inherit;
    }
  }
`;

export const ContentDiv = styled.div`
  margin: 1rem auto;
  max-width: 800px;
  font-size: 18px;

  a {
    text-decoration: underline;
    font-weight: bold;
    color: inherit;
  }
`;
